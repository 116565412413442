import { booleanAttribute, Component, computed, HostBinding, input } from '@angular/core';

import { Achievement } from '@pt/models';

type AchievementNamePosition = 'top' | 'right' | 'bottom' | 'left';

@Component({
  standalone: true,
  selector: 'pt-achievement',
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.scss'],
})
export class AchievementComponent {
  isHoverable = input<boolean, unknown>(false, { transform: booleanAttribute });
  achievement = input.required<Achievement>();
  withName = input<boolean, unknown>(false, { transform: booleanAttribute });
  namePosition = input<AchievementNamePosition>('bottom');
  // There are places where achievement model is returned without `achievedCount` property,
  // and it means that this achievement is already achieved
  isAchieved = computed(() => (this.achievement().achievedCount ?? true)
    || this.achievement().achievedCount > 0);

  className = computed(() => {
    const hoverable = this.isHoverable() ? 'pt-achievement_hoverable' : '';
    const achieved = this.isAchieved() ? 'pt-achievement_achieved' : '';
    const withName = this.withName() ? 'pt-achievement_with-name' : '';
    const withNamePosition = this.withName()
      ? `pt-achievement_with-name-${this.namePosition()}`
      : '';

    return [hoverable, achieved, withName, withNamePosition].join(' ');
  });

  @HostBinding('class')
  private get _class() {
    return this.className();
  }
}
