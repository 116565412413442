export var DisposalMethod;
(function (DisposalMethod) {
  DisposalMethod[DisposalMethod["Replace"] = 0] = "Replace";
  DisposalMethod[DisposalMethod["Combine"] = 1] = "Combine";
  DisposalMethod[DisposalMethod["RestoreBackground"] = 2] = "RestoreBackground";
  DisposalMethod[DisposalMethod["RestorePrevious"] = 3] = "RestorePrevious";
  DisposalMethod[DisposalMethod["UndefinedA"] = 4] = "UndefinedA";
  DisposalMethod[DisposalMethod["UndefinedB"] = 5] = "UndefinedB";
  DisposalMethod[DisposalMethod["UndefinedC"] = 6] = "UndefinedC";
  DisposalMethod[DisposalMethod["UndefinedD"] = 7] = "UndefinedD";
})(DisposalMethod || (DisposalMethod = {}));