<div class="pt-image-viewer">
  <div class="pt-image-viewer__header">
    <h1 class="pt-image-viewer__header_title">{{ imageData.title }}</h1>
    <button
      class="pt-image-viewer__header_btn-close"
      type="button"
      puButton
      size="m"
      uiType="tertiary"
      [onlyIcon]="true"
      (click)="close()"
    >
      <pu-icon
        iconName="pu-close"
        size="20"
      />
    </button>
  </div>
  <div
    class="pt-image-viewer__container"
    [class.pt-image-viewer__container-zoomed]="zoomLevel() > 1"
    (click)="$event.stopPropagation()"
  >
    <img
      [alt]="imageData.title"
      [src]="imageData.url"
      [style]="{'transform': 'scale(' + zoomLevel() + ')'}"
    />
  </div>
  <div class="pt-image-viewer__footer">
    <pt-slider-control
      [formControl]="sliderControl"
      (click)="$event.stopPropagation()"
      (puControlValueChanges)="onSliderChange($event)"
    />
  </div>
</div>
