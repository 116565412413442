import { getRangeValue, parseAlpha } from "./NumberUtils.js";
var RgbIndexes;
(function (RgbIndexes) {
  RgbIndexes[RgbIndexes["r"] = 1] = "r";
  RgbIndexes[RgbIndexes["g"] = 2] = "g";
  RgbIndexes[RgbIndexes["b"] = 3] = "b";
  RgbIndexes[RgbIndexes["a"] = 5] = "a";
})(RgbIndexes || (RgbIndexes = {}));
export class RgbColorManager {
  constructor() {
    this.key = "rgb";
    this.stringPrefix = "rgb";
  }
  handleColor(color) {
    const colorValue = color.value,
      rgbColor = colorValue.rgb ?? color.value;
    if (rgbColor.r !== undefined) {
      return rgbColor;
    }
  }
  handleRangeColor(color) {
    const colorValue = color.value,
      rgbColor = colorValue.rgb ?? color.value;
    if (rgbColor.r !== undefined) {
      return {
        r: getRangeValue(rgbColor.r),
        g: getRangeValue(rgbColor.g),
        b: getRangeValue(rgbColor.b)
      };
    }
  }
  parseString(input) {
    if (!input.startsWith(this.stringPrefix)) {
      return;
    }
    const regex = /rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(,\s*([\d.%]+)\s*)?\)/i,
      result = regex.exec(input),
      radix = 10,
      minLength = 4,
      defaultAlpha = 1;
    return result ? {
      a: result.length > minLength ? parseAlpha(result[RgbIndexes.a]) : defaultAlpha,
      b: parseInt(result[RgbIndexes.b], radix),
      g: parseInt(result[RgbIndexes.g], radix),
      r: parseInt(result[RgbIndexes.r], radix)
    } : undefined;
  }
}