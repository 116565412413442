<div class="pt-achievement__img-wrapper">
  <img
    class="pt-achievement__img"
    [src]="achievement().image.url"
    [alt]="achievement().image.name"
  >

  @if (achievement().achievedCount >= 2) {
    <span class="pt-achievement__counter">
      x{{ achievement().achievedCount }}
    </span>
  }
</div>

@if (withName()) {
  <h3 class="pt-achievement__title">
    {{ achievement().name }}
  </h3>
}
