<a
  class="pt-logo"
  routerLink="/"
>
  <svg>
    <use
      [attr.xlink:href]="assetsHost+'/assets/icons.svg#'+logoName"
      [attr.href]="assetsHost+'/assets/icons.svg#'+logoName"
    />
  </svg>
</a>
