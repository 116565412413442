<img
  class="pt-not-found__art"
  srcset="assets/404/404-art@2x.png 2x"
  src="assets/404/404-art.png"
  alt="Not Found"
/>

<div class="pt-not-found__body">
  <img
    class="pt-not-found__symbol"
    src="assets/404/404.png"
    alt="404"
  />

  <p
    class="pt-not-found__description"
    translate="notFoundPage.description"
  >
  </p>

  <a
    class="pt-not-found__btn xl:pu-button_l"
    [routerLink]="'/'"
    translate="notFoundPage.backToHomePage"
  >
  </a>
</div>
