<img
  class="pu-avatar__img"
  *ngIf="image; else text"
  [src]="image"
  alt="Avatar"
>

<ng-template #text>
  <p
    class="pu-avatar__text"
    *ngIf="nameFirstLetter; else placeholder"
  >
    {{ nameFirstLetter }}
  </p>
</ng-template>

<ng-template #placeholder>
  <pu-icon iconName="user"/>
</ng-template>

<button
  class="pu-avatar__btn"
  type="button"
  *ngIf="isHover"
>
  <pu-icon iconName="edit"/>
</button>
